
import { computed, defineComponent, ref, onMounted, reactive, nextTick } from "vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import router from "@/router";
import moment from "moment";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ConfettiExplosion from "vue-confetti-explosion";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "dashboard",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    ConfettiExplosion
  },
  data() {
    return {
      current_page: 0,
      loading: false,
      filter: {
        dificulty: "",
        status: "",
        search: ""
      }
    };
  },
  setup(props) {
    const store = useStore();
    
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
    store.dispatch(Actions.GET_PATHWAY, props.id);

    const pathway = computed(() => {
      return store.getters.getCurrentPathway;
    });
    const user = computed(() => store.getters.currentUser);

    onMounted(() => {
      setCurrentPageTitle("Machines List");
    });

    const shareOnLinkedin = (pathway_id, user_id) => {
      // shareContent with this URL: https://share.hackingclub.com/achievement/pathway/1/user/2
      const url = `https://share.hackingclub.com/achievement/pathway/${pathway_id}/user/${user_id}`;
      const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
      window.open(linkedinUrl, "_blank");

    };


    const visible = ref(false);

    const explode = async () => {
      // play ack.aac sound
      const audio = new Audio("/media/ack3.aac");
      audio.volume = 0.3;
      audio.play();

      // exampleModal
      
      // create fake button and click it
      const button = document.createElement("button");
      button.setAttribute("data-bs-toggle", "modal");
      button.setAttribute("data-bs-target", "#kt_modal_1");
      document.body.appendChild(button);
      button.click();
      document.body.removeChild(button);
      visible.value = false;
      await nextTick();
      visible.value = true;
    };

    return {
      moment,
      router,
      pathway,
      store,
      currentLanguage,
      visible,
      explode,
      user,
      shareOnLinkedin
    };
  },
  watch: {
    pathway: {
      handler: function (val) {
        console.log("ACK RQ: "+val.acknowledge_required)
        if(val.acknowledge_required){
          this.explode();
          ApiService.get(`/pathways/${this.pathway.id}/ack`).then((response) => {
            console.log(response);
          });

        }
      },
      deep: true,
    },
  },
  methods: {}
});
